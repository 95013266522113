@import '../../styles/utils/mixins';
@import '../../styles/utils/variables';

.page {
  padding: 40px 0;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include onTablet {
    gap: 40px;
  }

  @include onLaptop {
    height: calc(100vh - 295px);
    flex-direction: row;
    align-items: center;
  }
}

.img {
  position: relative;
  width: 100%;
  object-fit: contain;

  @include onTablet {
    height: 350px;
  }

  @include onLaptop {
    width: 40%;
    height: auto;
  }
}

.data {
  width: 100%;
  @include onLaptop {
    width: 60%;
    height: 100%;
    overflow-y: scroll;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;
  margin-bottom: 16px;

  & span {
    color: $secondary;
  }

  @include onTablet {
    font-size: 32px;
  }
  @include onLaptop {
    font-size: 50px;
    margin-bottom: 32px;
  }
}

.item {
  margin-bottom: 32px;
}

.itemTitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;

  @include onTablet {
    font-size: 24px;
  }

  @include onLaptop {
    font-size: 32px;
  }
}

.itemDate {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;

  @include onTablet {
    font-size: 18px;
  }
}

.block {
  margin-bottom: 16px;
}

.blockContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.itemSubtitle {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;

  @include onTablet {
    font-size: 18px;
  }
}

.itemText {
  margin-left: 14px;

  @include onTablet {
    font-size: 16px;
  }
}