@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.header {
  padding: 12px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--active {
    background-color: $background;
  }

  @include onTablet {
    padding: 16px 32px;
  }

  @include onLaptop {
    padding: 24px 40px;
    background-color: #fff;
  }
}

.logo {
  height: 40px;
  cursor: pointer;
}

.menu {
  display: none;
  height: calc(100% - 68px);
  margin-top: 68px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: $background;

  &--active {
    display: flex;
  }

  @include onTablet {
    height: calc(100vh - 76px);
    margin-top: 76px;
  }
  
  @include onLaptop {
    display: flex;
    flex-direction: row;
    position: static;
    height: auto;
    margin-top: 0;
    gap: 16px;
    background-color: #fff;
  }
}

.item {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 0 0 24px;
  border-top: 1px solid rgba(136, 136, 156, 0.3);

  @include onTablet {
    padding: 0 0 0 32px;
  }

  @include onLaptop {
    flex: auto;
    padding: 0;
    border-top: none;
  }
}

.item, .item a {
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  color: $primary;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  width: 100%;

  &:hover {
    color: $secondary;
  }

  @include onLaptop {
    border-bottom: 2px solid transparent;
    font-size: 16px;
    width: auto;
  }
}

.item--active, .item--active a {
  color: $secondary;
}

.item--active {
  @include onLaptop {
    border-bottom: 2px solid $secondary;
  }
}

.menuBtn {
  @include onLaptop {
    display: none;
  }
}