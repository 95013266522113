@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 24px 0 32px;
  background-image: url('/images/built-out-of-necessity.png');
  background-size: cover;

  @include onTablet {
    padding: 32px 0 40px;
  }

  @include onLaptop {
    padding: 64px 0 80px;
  }

  @include onDesktop {
    padding: 96px 0 112px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #fff;

  & span {
    opacity: 0.7;
  }

  @include onTablet {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }
}

.items {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include onTablet {
    padding-top: 32px;
  }

  @include onLaptop {
    flex-direction: row;
    padding-top: 40px;
  }
}

.item {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;

  @include onLaptop {
    flex-basis: 25%;
    flex-direction: column;
  }
}

.item_content {
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  @include onLaptop {
    min-height: 380px;
  }
}

.item_icon {
  width: 40px;
  height: 40px;
  object-fit: cover;

  @include onTablet {
    display: none;
    width: 72px;
    height: 72px;
  }

  &_desktop {
    display: none;

    @include onTablet {
      display: block;
    }
  }
}

.item_title {
  padding-top: 12px;
  font-weight: 700;
  font-size: 20px;
  line-height: 133.4%;
  color: $primary;

  @include onTablet {
    font-size: 24px;
  }
}

.item_text {
  padding-top: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: $primary_light;

  @include onTablet {
    font-size: 14px;
  }
}

.arrow {
  width: 35px;
  height: 124px;
  align-self: flex-end;

  @include onLaptop {
    display: none;
  }

  &--desktop {
    width: 124px;
    height: 40px;
    display: none;
  
    @include onLaptop {
      display: block;
    }
  }
}
