@import '../../styles/utils/mixins';

.page {
  padding: 32px 0 40px;
  background-image: url('/images/more_bg.png');
  background-size: cover;

  @include onTablet {
    padding: 40px 0 48px;
  }

  @include onLaptop {
    padding: 64px 0 80px;
  }

  @include onDesktop {
    padding: 136px 0 152px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.5px;
  color: #fff;

  & span {
    opacity: 0.7;
  }

  @include onTablet {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }
}

.buttons {
  padding-top: 24px;
  gap: 8px;
  @include flex-center;

  @include onLaptop {
    padding-top: 32px;
  }

  @include onDesktop {
    padding-top: 48px;
  }
}