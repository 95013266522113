// Base: Root styles
@import '../utils/variables';
@import '../utils/mixins';

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

button {
  background: none;
  border: none;
  outline: 0;
  font-family: 'Montserrat', sans-serif;
  &:hover {
    cursor: pointer;
  }
}

main {
  min-height: calc(100vh - 319px);

  @include onTablet {
    min-height: calc(100vh - 302px);
  }

  @include onLaptop {
    min-height: calc(100vh - 207px);
  }
}

input {
  font-family: 'Montserrat', sans-serif;
}

.page {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  touch-action: none;

  @include onLaptop {
    overflow: auto;
  }
}
