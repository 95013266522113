@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.page {
  padding: 24px 0 32px;
  background-color: #fff;

  @include onTablet {
    padding: 32px 0 40px;
  }

  @include onLaptop {
    padding: 64px 0 72px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;

  @include onTablet {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }
}

.subtitle {
  padding-top: 24px;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: $primary;
}

.text {
  padding-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary_light;

  & a {
    display: inline;
    color: $secondary;

    &:hover {
      text-decoration: underline;
    }
  }
}

.item {
  margin-top: 24px;
  padding: 14px 16px 14px 20px;
  display: flex;
  gap: 16px;
  border-radius: 4px;

  &--info {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #6464E6;
  }

  &--warning {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336;
  }
}

.item_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $primary;
}

.item_text {
  padding-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: $primary;
}
