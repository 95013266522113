@import '../../../styles/utils/mixins';

.mySwiper {
  @include onLaptop {
    display: none;
  }
}

.swiper {
  width: calc(100% + 48px);
  padding: 0 24px;
  height: 100%;
  margin-left: -24px;

  @include onTablet {
    width: calc(100% + 64px);
    padding-right: 32px;
    padding: 0 32px;
    margin-left: -32px;
  }
}

.swiper-wrapper {
  display: flex;
  align-items: stretch;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: auto;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-pagination {
  position: relative;
  bottom: 0 !important;
  margin-top: 16px;
}

.swiper-pagination-bullet-active {
  background-color: #6464E6;
}