@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 24px 0 0 0;
  position: relative;
  background-color: $background;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include onTablet {
    padding: 32px 0 0 0;
  }

  @include onLaptop {
    display: block;
    padding: 64px 0;
    min-height: 533px;
  }

  @include onDesktop {
    padding: 96px 0;
    min-height: 640px;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 60% 80%;
  margin-top: 32px;
  max-height: 420px;

  @include onLaptop {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    max-height: 100%;
  }
}

.content {
  width: 100%;

  @include onLaptop {
    max-width: 488px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;

  & span {
    color: $secondary;
  }

  @include onTablet {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }
}

.text {
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary_light;
  text-align: justify;

  @include onTablet {
    font-size: 16px;
  }
}
