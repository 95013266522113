@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  position: relative;
  padding: 0 0 32px;
  background: linear-gradient(45deg, #9692EA 0%, #5252D4 100%);

  @include onTablet {
    padding: 0 0 40px;
  }

  @include onLaptop {
    padding: 64px 0;
    height: 472px;
  }

  @include onDesktop {
    height: 696px;
  }
}

.img {
  width: 100%;
  height: 280px;
  object-fit: cover;
  object-position: 50% 10%;

  @include onTablet {
    height: 420px;
  }

  @include onLaptop {
    position: absolute;
    top: 0;
    right: 0;
    width: 55%;
    height: 100%;
  }
}

.content {
  @include onLaptop {
    max-width: 352px;
  }
}

.title {
  padding-top: 24px;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #fff;

  @include onTablet {
    padding-top: 32px;
    font-size: 32px;
  }
  @include onLaptop {
    padding-top: 0;
    font-size: 50px;
  }
}

.subtitle {
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: #fff;
  text-align: justify;

  @include onTablet {
    font-size: 16px;
  }
}
