@import '../utils/mixins';

.container {
  max-width: 1104px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;

  @include onTablet {
    padding-right: 32px;
    padding-left: 32px;
  }

  @include onDesktop {
    max-width: 1184px;
  }
}
