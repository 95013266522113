@mixin onTablet {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin onLaptop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
