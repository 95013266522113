@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 16px 0 72px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 68px);

  @include onTablet {
    min-height: calc(100vh - 76px);
  }

  @include onLaptop {
    min-height: calc(100vh - 92px);
  }
}

.content {
  display: flex;
  flex-direction: column;

  @include onTablet {
    gap: 24px;
  }

  @include onLaptop {
    flex-direction: row;
    align-items: center;
  }
}

.img {
  order: -1;
  position: relative;
  width: 100%;
  object-fit: contain;

  @include onTablet {
    height: 350px;
  }

  @include onLaptop {
    order: 0;
    width: 55%;
    height: auto;
  }
}

.data {
  width: 100%;
  @include onLaptop {
    width: 45%;
  }
}

.title {
  padding-top: 24px;
  font-weight: 700;
  font-size: 32px;
  line-height: 116.7%;
  letter-spacing: -1px;
  color: $primary;

  & span {
    color: $secondary;
  }

  @include onTablet {
    font-size: 50px;
  }

  @include onLaptop {
    padding-top: 0;
    font-size: 58px;
  }
}

.subtitle {
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary_light;
  text-align: justify;

  @include onTablet {
    padding-top: 24px;
    font-size: 16px;
  }

  @include onLaptop {
    padding-top: 32px;
  }
}

.buttons {
  display: flex;
  gap: 12px;
  padding-top: 24px;

  @include onTablet {
    padding-top: 32px;
  }

  @include onLaptop {
    padding-top: 64px;
  }
}

