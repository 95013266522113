@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  position: relative;
  padding: 0 0 32px;

  @include onTablet {
    padding: 0 0 40px;
  }

  @include onLaptop {
    padding: 64px 0 72px;
  }

  @include onDesktop {
    padding: 96px 0 204px;
  }
}

.content {
  @include onLaptop {
    max-width: 600px;
  }
}

.title {
  padding-top: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;

  & span {
    color: $secondary;
  }

  @include onTablet {
    padding-top: 32px;
    font-size: 32px;
  }

  @include onLaptop {
    padding-top: 0;
    font-size: 50px;
  }
}

.img {
  width: 100%;
  height: 318px;
  object-fit: cover;

  @include onTablet {
    height: 350px;
  }

  @include onLaptop {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
  }

  @include onDesktop {
    width: 45%;
  }
}

.items {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include onTablet {
    padding-top: 32px;
    gap: 16px;
  }

  @include onLaptop {
    padding-top: 40px;
  }
}

.item {
  padding: 16px;
  background: $background;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 160px;
  gap: 8px;

  & span {
    color: $secondary;
  }

  @include onTablet {
    padding: 12px 16px;
    gap: 16px;
    flex-direction: row;
    align-items: center;
  }
}

.item_icon {
  width: 72px;
  height: 72px;
  object-fit: cover;
}

.item_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 133.4%;
  color: $primary;

  @include onTablet {
    font-size: 24px;
  }
}

.item_text {
  padding-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: $primary_light;
  text-align: justify;
}