@import '../../styles/utils/mixins';

.paper {
  padding: 24px 16px;
  position: relative;

  @include onTablet {
    padding: 48px 32px 56px;
  }

  @include onLaptop {
    padding: 48px 48px 56px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: #212126;

  @include onTablet {
    font-size: 32px;
  }
}

.label {
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #464650;
}
