@import '../../../styles/utils/mixins';
@import '../../../styles/utils/variables';

.page {
  padding: 24px 0 32px;

  @include onTablet {
    padding: 32px 0 40px;
  }
  @include onLaptop {
    padding: 64px 0;
  }
  @include onDesktop {
    padding: 96px 0;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;

  & span {
    color: $secondary;
  }

  @include onTablet {
    font-size: 32px;
  }
  @include onLaptop {
    font-size: 50px;
  }
}

.subtitle {
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary_light;

  @include onTablet {
    font-size: 16px;
  }
}

.content {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include onTablet {
    gap: 24px;
    padding-top: 32px;
  }

  @include onLaptop {
    padding-top: 36px;
    flex-direction: row;
    gap: 40px;
  }
}

.desktop {
  width: 100%;
  max-height: 258px;
  object-fit: contain;
  object-position: top;

  @include onTablet {
    max-height: 444px;
  }

  @include onLaptop {
    width: 50%;
  }
}

.buttons {
  flex-grow: 1;
  display: none;
  flex-direction: column;
  gap: 4px;

  @include onLaptop {
    display: flex;
  }

  @include onDesktop {
    width: 544px;
  }
}

.button {
  width: 100%;
  height: 100%;
  padding: 12px 24px 12px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: $background;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  @include onTablet {
    padding: 22px 24px 22px 12px;
  }

  @include onLaptop {
    padding: 12px 24px 12px 12px;
  }

  &:hover {
    background-color: rgba($secondary, 0.3);
  }

  &--active {
    background-color: $secondary;
    min-height: 130px;

    @include onTablet {
      min-height: 110px;
    }

    @include onDesktop {
      min-height: 90px;
    }

    &:hover {
      background-color: $secondary;
    }
  }
}

.button_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: $primary;
  text-align: left;

  &--active {
    color: #fff;
  }
}

.button_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
}

.icon_progress {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  @include flex-center;
}

.icon_wrapper {
  width: 42px;
  height: 42px;
  @include flex-center;
  border-radius: 50%;
}

.icon {
  background-color: $background;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  &--active {
    background-color: $secondary;
  }
}