@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 24px 0;
  background-color: #fff;

  @include onTablet {
    padding: 32px 0;
  }

  @include onLaptop {
    padding: 64px 0;
  }

  @include onDesktop {
    padding: 144px 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @include onTablet {
    gap: 32px;
  }

  @include onLaptop {
    flex-direction: row;
    gap: 40px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;

  @include onTablet {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }
}

.text {
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary_light;
  text-align: justify;

  @include onTablet {
    font-size: 16px;
  }
}

.img {
  height: 240px;
  object-fit: cover;
  border-radius: 4px;
  width: 100%;

  @include onTablet {
    height: 360px;
  }

  @include onLaptop {
    max-width: 45%;
  }
}
