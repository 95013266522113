@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 0 0 32px;
  position: relative;
  background-color: $background;

  @include onTablet {
    padding: 0 0 40px;
  }

  @include onLaptop {
    padding: 64px 0 96px;
  }

  @include onDesktop {
    padding: 96px 0 136px;
    min-height: 650px;
  }
}

.title {
  padding-top: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;

  & span {
    color: $secondary;
  }

  @include onTablet {
    padding-top: 32px;
    font-size: 32px;
  }

  @include onLaptop {
    padding-top: 0;
    font-size: 50px;
  }
}

.text {
  padding-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary;
  min-height: 550px;
  text-align: justify;

  @include onTablet {
    font-size: 16px;
    min-height: 432px;
  }

  @include onLaptop {
    font-size: 16px;
    min-height: 336px;
  }
}

.img {
  width: 100%;
  object-fit: cover;
  object-position: right;
  height: 240px;

  @include onTablet {
    height: 360px;
  }

  @include onLaptop {
    position: absolute;
    top: 0;
    right: 0;
    width: 35%;
    height: 100%;
  }
}

.tabs {
  margin-top: 24px;
  min-height: auto;
}

.tab {
  min-width: max-content;
  min-height: auto;
  flex-grow: 1;
  padding: 8px 14px !important;
  background-color: #fff;
  border-right: 1px solid rgba(136, 136, 156, 0.3);
  font-weight: 600;
  font-size: 14px !important;
  line-height: 140%;
  color: $primary_light;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  text-transform: capitalize;

  &:hover {
    background-color: rgba($secondary, 0.3);
  }

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border: none;
    border-radius: 0 4px 4px 0;
  }

  &--active {
    background-color: $secondary;
    color: #fff !important;

    &:hover {
      background-color: $secondary;
    }
  }
}
