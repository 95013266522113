@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  position: relative;
  padding: 24px 0 0;
  background-color: $background;

  @include onTablet {
    padding: 32px 0 0;
  }

  @include onLaptop {
    padding: 64px 0 80px;
  }

  @include onDesktop {
    padding: 96px 0 112px;
  }
}

.img {
  margin-top: 32px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 85%;

  @include onLaptop {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
  }
}

.content {
  width: 100%;

  @include onLaptop {
    max-width: 488px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;
  max-width: 340px;

  @include onTablet {
    font-size: 32px;
  }

  @include onTablet {
    font-size: 50px;
  }
}

.items {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item {
  padding: 20px 24px 20px 12px;
  background-color: #fff;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.item_icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.item_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: $primary;
}
