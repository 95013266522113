@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 24px 0 32px;
  background-color: $background;

  @include onTablet {
    padding: 32px 0 40px;
  }

  @include onLaptop {
    padding: 64px 0;
  }

  @include onDesktop {
    padding: 96px 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include onTablet {
    gap: 32px;
  }

  @include onLaptop {
    flex-direction: row;
    gap: 40px;
  }
}

.img {
  height: 258px;
  object-fit: contain;
  order: 1;

  @include onTablet {
    height: 444px;
  }

  @include onLaptop {
    order: 0;
    max-width: 50%;
  }
}

.titles {
  @include onTablet { display: none; }
  @include onLaptop { display: block; }

  &--tablet {
    display: none;
    @include onTablet { display: block; };
    @include onLaptop { display: none; };
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;

  & span {
    color: $secondary;
  }

  @include onTablet {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }
}

.text {
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary_light;
  text-align: justify;

  @include onTablet {
    font-size: 16px;
  }
}
