@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 24px 0 32px;
  background-color: $background;
  overflow: hidden;

  @include onTablet {
    padding: 32px 0 40px;
  }

  @include onLaptop {
    padding: 64px 0 80px;
  }

  @include onDesktop {
    padding: 96px 0 112px;
  }
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.5px;
  color: $primary;

  & span {
    color: $secondary;
  }

  @include onTablet {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }
}

.subtitle {
  text-align: center;
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary_light;

  @include onTablet {
    font-size: 16px;
  }
}

.items {
  padding-top: 24px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  @include onTablet {
    padding-top: 32px;
    gap: 16px;
  }

  @include onLaptop {
    padding-top: 40px;
  }
}

.item {
  width: 100%;
  padding: 16px;
  display: flex;
  gap: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  & span {
    color: $secondary;
  }

  @include onTablet {
    padding: 24px;
  }

  @include onLaptop {
    width: calc(50% - 8px);
  }
}

.icon {
  width: 40px;
  height: 40px;
  object-fit: cover;

  @include onTablet {
    display: none;
    width: 72px;
    height: 72px;
  }

  &_desktop {
    display: none;

    @include onTablet {
      display: block;
    }
  }
}

.item_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 133.4%;
  color: $primary;

  @include onTablet {
    font-size: 24px;
  }
}

.item_text {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: $primary_light;
  text-align: justify;
}
