@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  position: relative;
  padding: 24px 0 0;
  background-color: $background;

  @include onTablet {
    padding: 32px 0 0;
  }
  
  @include onLaptop {
    padding: 64px 0 80px;
    height: 406px;
  }

  @include onDesktop {
    height: 696px;
  }
}

.img {
  padding-top: 24px;
  position: relative;
  width: 100%;
  height: 280px;
  object-fit: cover;
  object-position: 50% 15%;

  @include onTablet {
    padding-top: 32px;
  }
  
  @include onLaptop {
    padding-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    height: 100%;
  }
}

.content {
  @include onLaptop {
    max-width: 352px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;

  @include onTablet {
    font-size: 32px;
  }
  
  @include onLaptop {
    font-size: 50px;
  }
}

.subtitle {
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary;
  text-align: justify;

  @include onTablet {
    font-size: 16px;
  }
}
