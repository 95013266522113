@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 24px 0 32px;
  background-color: $background;

  @include onLaptop {
    padding: 32px 0 40px;
  }

  @include onLaptop {
    padding: 64px 0 80px;
  }

  @include onDesktop {
    padding: 96px 0 112px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.5px;
  color: $primary;

  & span {
    color: $secondary;
  }

  @include onLaptop {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }
}

.items {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include onTablet {
    padding-top: 32px;
    gap: 16px;
  }

  @include onLaptop {
    flex-direction: row;
    padding-top: 48px;
  }
}

.item {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  @include onTablet {
    padding: 24px;
  }

  @include onLaptop {
    flex-basis: calc(100% / 3);
    padding: 24px 16px 40px;
    flex-direction: column;
    gap: 24px;
  }
}

.item_data {
  text-align: center;
}

.item_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;

  @include onTablet {
    width: 120px;
    height: 120px;
  }
}

.item_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 133.4%;
  color: $primary;

  @include onTablet {
    font-size: 24px;
  }
}

.item_subtitle {
  padding-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary_light;
  text-align: center;

  @include onTablet {
    padding-top: 8px;
  }

  @include onLaptop {
    font-size: 16px;
  }
}

.item_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 132%;
  color: #88889C;
}