@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.page {
  min-height: calc(100vh - 207px);
  padding: 24px 0 32px;
  background: linear-gradient(45deg, rgba(150, 146, 234, 0.9) 0%, rgba(82, 82, 212, 0.9) 100%), url('/images/client-area-bg.jpeg');
  background-size: cover;

  @include onTablet {
    padding: 32px 0 40px;
  }

  @include onLaptop {
    padding: 64px 0 80px;
  }

  @include onDesktop {
    padding: 96px 0 112px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  & img {
    border: 1px solid rgba(255,255,255,0.7);
    border-radius: 50%;
  }
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #fff;

  & span {
    text-transform: uppercase;
    opacity: 0.7;
  }

  @include onTablet {
    font-size: 24px;
  }

  @include onLaptop {
    font-size: 32px;
  }
}

.items {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include onTablet {
    padding-top: 32px;
  }

  @include onLaptop {
    flex-direction: row;
    padding-top: 40px;
  }
}

.item {
  width: 100%;
  padding: 16px;
  background: #fff;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  @include onLaptop {
    min-height: 380px;
  }
}

.item_icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(100, 100, 230, 0.12);
  color: $secondary;
  font-size: 20px;
  @include flex-center;

  @include onTablet {
    width: 72px;
    height: 72px;
    font-size: 36px;
  }
}

.item_title {
  padding-top: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 133.4%;
  color: $primary;

  @include onTablet {
    font-size: 22px;
  }
}

.item_text {
  padding-top: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: $primary_light;

  @include onTablet {
    font-size: 14px;
  }
}

.hint {
  margin-top: 16px;
  width: 100%;
  padding: 8px;
  background: #fff;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  color: $primary_light;

  @include onTablet {
    font-size: 16px;
  }
}
