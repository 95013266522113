@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 24px 0 32px;
  // background-color: $background;

  @include onTablet {
    padding: 32px 0 40px;
  }

  @include onLaptop {
    padding: 64px 0 80px;
  }

  @include onDesktop {
    padding: 136px 0 152px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: $primary;

  @include onTablet {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }
}

.items {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;

  @include onTablet {
    padding-top: 32px;
    gap: 16px;
  }

  @include onLaptop {
    flex-direction: row;
    padding-top: 48px;
  }
}

.item {
  flex-basis: 100%;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  gap: 16px;

  @include onTablet {
    padding: 24px;
  }

  @include onLaptop {
    flex-basis: calc(100% / 3);
  }
}

.icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.item_title {
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: $primary;

  @include onTablet {
    font-size: 20px;
  }
}

.item_text {
  padding-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.15px;
  color: $primary_light;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: $secondary;
  }
}
