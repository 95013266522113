@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.root {
  padding: 16px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 16px;
  margin-inline: 16px;
  display: flex;
  flex-direction: column;
  background: $secondary;
  box-shadow: 0px 4px 20px rgba(207, 176, 122, 0.2);
  border-radius: 4px;
  z-index: 5;
  gap: 12px;

  @include onLaptop {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @include onTablet {
    margin-bottom: 0;
    padding-right: 24px;
  }
}

.iconWrapper {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: rgba($primary, 0.12);
  border-radius: 50%;
  color: $primary;
  margin-right: 16px;

  @include onTablet {
    margin-right: 24px;
  }
}

.text {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
  margin-bottom: 0;

  @include onLaptop {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.actions { 
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
}
