@import '../../../styles/utils/variables';
@import '../../../styles/utils/mixins';

.page {
  padding: 24px 0 32px;
  background-image: url('/images/security_bg.png');
  background-size: cover;
  background-position: 70% 0;

  @include onTablet {
    padding: 32px 0 40px;
  }

  @include onLaptop {
    padding: 64px 0 80px;
  }

  @include onDesktop {
    padding: 96px 0 112px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.5px;
  color: #fff;

  @include onTablet {
    font-size: 32px;
  }

  @include onLaptop {
    font-size: 50px;
  }

  & span {
    opacity: 0.7;
  }
}

.subtitle {
  padding-top: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: #fff;

  @include onTablet {
    padding-top: 16px;
  }
}

.items {
  padding-top: 24px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  @include onTablet {
    padding-top: 32px;
    gap: 16px;
  }

  @include onLaptop {
    padding-top: 40px;
  }
}

.item {
  padding: 20px 24px 20px 12px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  display: flex;
  gap: 12px;
  align-items: center;

  @include onTablet {
    padding: 24px 24px 24px 12px;
  }

  @include onLaptop {
    width: calc(50% - 8px);
  }
}

.icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.item_text {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: $primary;
}
