@import '../../styles/utils/variables';
@import '../../styles/utils/mixins';

.footer {
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #E6E6EB;

  display: flex;
  flex-direction: column;
  gap: 24px;

  @include onTablet {
    padding: 40px 32px 48px;
  }

  @include onLaptop {
    padding: 44px 40px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include onTablet {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.15px;
  color: $primary;

  @include onTablet {
    font-size: 16px;
  }

  & a {
    color: $primary;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $secondary;
    }
  }
}

.divider {
  @include onLaptop {
    display: none;
  }
}

.copyright {
  @include onLaptop {
    order: -1;
  }
}

.language_select {
  max-width: max-content;
  margin-inline: auto;

  @include onLaptop {
    margin-inline: inherit;
  }
}
